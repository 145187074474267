import React from "react"
import { Link } from "gatsby"
import "./404.scss"
import ErrorLayout from "../components/Layout/notFound"

const NotFoundPage = () => (
  <ErrorLayout>
    <div className="not-found-container">
      <div className="not-found">
        <div className="not-found-header">
          4<span className="not-found-header-o">0</span>4
        </div>
        <div className="not-found-description">
          Vi kan tyvärr inte hitta sidan som du letar efter...
        </div>
        <Link to="/sv/" className="not-found-startpage">
          <div className="not-found-startpage-container">
            <div className="not-found-startpage-container-label">
              Ta mig till startsidan
            </div>
          </div>
        </Link>

        <img
          src="/logos/etv/etv2.png"
          alt="everysport tv"
          className="not-found-logo"
        />
      </div>
    </div>
  </ErrorLayout>
)
export default NotFoundPage
