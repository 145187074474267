import React from "react"
import { Helmet } from "react-helmet"
import * as PropTypes from "prop-types"
import config from "../../../data/SiteConfig"

const propTypes = {
  children: PropTypes.func.isRequired,
}

const DefaultLayout = props => (
  <div className="not-found-container">
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta name="description" content={config.siteDescription} />
    </Helmet>

    <div className="not-found-container">{props.children}</div>
  </div>
)

DefaultLayout.propTypes = propTypes

export default DefaultLayout
